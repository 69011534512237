
.hamburger-menu-container {
    /*START POINT*/
    z-index: 1001;
    opacity: 0;
    transform: translateX(-300px);
    transition: opacity var(--timing-for-transition), transform var(--timing-for-transition);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}
.hamburger-menu-backdrop {
    z-index: -1;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
}
.hamburger-menu-container {
    display: grid;
    grid-template-columns: 20rem;
    grid-template-rows: 16rem auto 3rem;
    background: rgb(32, 32, 42);
    box-shadow: rgba(15, 15, 20, 0.2) 0 3px 8px 0;
}

.hamburger-menu-container__open {
    z-index: 1001;
    opacity: 1;
    transform: translateX(0);
}
.hamburger-menu-backdrop__show {
    z-index: 1000;
    opacity: 1;
}

/*  More Than 1024px its an PC
*   Have a look: https://www.mydevice.io/#compare-devices
*/
@media (min-width: 1024px) {
    .hamburger-menu-container {
        z-index: 1001;
        opacity: 1;
        transform: translateX(0);
    }
    .hamburger-menu-backdrop{
      display: none;
    }
}

.hamburger-menu__footer-container {
    grid-column-start: 2;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 4;
    background: #24242E;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hamburger-menu__footer-container svg{
    max-height: 2rem;
    overflow: hidden;
    margin: 1rem 1rem;
    fill: #8c8c8e;
}
.hamburger-menu__footer-container svg:hover{
    fill: var(--yellow);
    transform: scale(1.1);
}
.hamburger-menu__header-container {
    grid-column-start: 2;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 2;
    background: linear-gradient(159deg, rgba(37, 37, 50, 0.98) 0%, rgba(35, 35, 45, 0.98) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
}

.hamburger-menu__header-container__text-info {
    margin-top: 20px;
    color: #8c8c8e;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.hamburger-menu__header-container__text-info a {
    color: aliceblue;
    display: block;
    margin-bottom: 10px;
    size: A4;
}

.hamburger-menu__header-container__text-info a:hover {
    color: #ffc107;
}

.hamburger-menu__header-container__item {
    width: 100px;
    height: 100px;
    /*border: 1px solid white*/
}

.hamburger-menu__header-container div a img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    z-index: 0;
}

.hamburger-menu__contents_container {
    padding: 1rem 0 0 1rem;
    color: #8c8c8e;
}
.hamburger-menu__contents_container ul{
    list-style-type: none;
}
.hamburger-menu__contents_container ul li{
    margin-bottom: 0.6rem;
}

.hamburger-menu__contents_container ul li a{

    color: #8c8c8e;
    padding-bottom: 0.1rem;
    border-bottom: 4px solid #8c8c8e;
    text-decoration: none;
}
.hamburger-menu__contents_container ul li a:hover,
.hamburger-menu__contents_container ul li a.is--route-active
{
    color: #ffc107;
    border-bottom: 4px solid #ffc107;
}
