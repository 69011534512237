.degree-item-container{
    background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
    width: 80%;
    box-shadow: rgba(15, 15, 20, 0.2) 0px 3px 8px 0px;
    /*border: 2px solid black;*/
    /*block-size: border-box;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
    max-width: 38rem;
}
.degree-item__text-container {
    width: 96%;

}
.degree-item__text-container section p {
    color: rgb(140, 140, 142);
}
.degree-item__text-container section h1 {
    color: rgb(255, 255, 255);
}
.degree-item__text-container section a {
    color: #8c8c8e;
    padding-bottom: 0.1rem;
    border-bottom: 4px solid #8c8c8e;
    text-decoration: none;
}
.degree-item-container section a:hover{
    color: #ffc107;
    border-bottom: 4px solid #ffc107;
}
.degree-item-container section {
    margin-bottom: 2rem;
    width: 100%;
}

.degree-image-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    horiz-align: center;
}

.degree-image-container img{
    max-width: 100%;
}


@media(min-width: 1400px) {
 .degree-item-container{
     flex-direction: row;
     max-width: 80rem;

     border-radius: 0;
     padding: 0.5rem;
 }
    .degree-item__text-container{
        margin-left: 1rem;
        width: 100%;
       min-height: 100%;
    }
    .degree-item__text-container h1{
         margin-top: 0;
     }

}
