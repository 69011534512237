.container {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 4rem auto;
    column-gap: 1rem;
    row-gap: 1rem;
}


.main_content {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.main_content h1 {
    color: aliceblue;
}

.main_content h1:hover {
    color: var(--yellow);
}

@media (min-width: 1024px) {
    .container {
        grid-template-columns: 20rem auto;
    }
    .main_content {
        grid-column-start: 2;
        grid-column-end: 3;
    }
}

.not-found{
    color: white;
}

.full-container {
    width: 100%;
}

.slit-in-vertical {
    animation: slit-in-vertical 0.45s ease-out both;
}
.puff-in-center {
    animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}
@keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}

@keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.fade-in {
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
