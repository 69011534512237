.projects-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: black;
}
.projects-card-container{
    display: flex;
    width: 80%;
    background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
    max-width: 540px;
    box-shadow: rgba(15, 15, 20, 0.2) 0 3px 8px 0;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
    flex-direction: column;
}
.projects-card-container> h1{
    margin-left: 1rem;
}
.projects-card__carousel-container{
    height: 250px;
    max-height: 300px;
}
@media (min-width: 1024px) {
    .projects-card-container{
        flex-direction: row;
        max-width: 80rem;
        max-height: 20rem;
        border-radius: 0;
        padding: 1.2rem;
    }
    .projects-card__carousel-container{
        width: 400px;
        min-width: 400px;
        max-height: none;
        height: 100%;
    }
}
