.about-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.about-card-container{
    display: flex;
    width: 80%;
    background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
    max-width: 540px;
    box-shadow: rgba(15, 15, 20, 0.2) 0 3px 8px 0;
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
}
.about-card__header__main-text-container{
    margin-left: 1rem;
}
.about-card__header__main-text-container h3,h4,h5,h6{
    color: white;
}
.about-card__header__main-text-container p{
    color: rgb(140, 140, 142);
}
.about-card__header-container{
    display: flex;
    flex-direction: column;
    width: 100%;

}
.about-card__header-container-img-container{
    height: 15rem;
    width: 100%;
    background-image:  url("../../../assets/perfil.jpg"),url("../../../assets/loading.svg");
    background-size:cover;
    background-position: center;
}

@media (min-width: 1024px) {
    .about-card-container{
        max-width: 80rem;
        border-radius: 0;
        padding: 1.2rem;
    }
    .about-card__header-container{
        flex-direction: row;
    }
    .about-card__header-container-img-container{
        height: 15rem;
        width: 15rem;
    }
}
