.nav-bar-container div {
    position: fixed;
    top: 0;
    background: rgb(32, 32, 42);
    box-shadow: rgba(15, 15, 20, 0.2) 0 3px 8px 0;
    width: 100%;
    height: 4rem;
    z-index: 10;
}

.nav-bar-container button {
    position: fixed;
    top: calc(2rem - 2.5rem / 2);
    height: 2.5rem;
    width: 2.5rem;
    left: 1rem;

    /*background: rgb(32, 32, 42);*/
    /*box-shadow: rgba(15, 15, 20, 0.2) 0 3px 8px 0;*/
    z-index: 1010;
}

.menu--button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    margin: 0;
    transition: transform var(--timing-for-transition) cubic-bezier(0.4, 0, 0.2, 1);
}


.menu--button__opened {
    transform: translateX(250px);
}
.menu--button__line {
    --menu--button__stroke-line: 6;
    fill: none;
    stroke: rgb(140, 140, 142);
    stroke-width: var(--menu--button__stroke-line);
    transition: stroke-dasharray var(--timing-for-transition) cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset var(--timing-for-transition) cubic-bezier(0.4, 0, 0.2, 1);
}
.menu--button:hover .menu--button__line {
    stroke: #fafafc;
}
.menu--button__line-2 {
    stroke-dasharray: 60 207;
    stroke-width: var(--menu--button__stroke-line);
}

.menu--button__line-3 {
    stroke-dasharray: 60 60;
    stroke-width: var(--menu--button__stroke-line);
}

.menu--button__line-4 {
    stroke-dasharray: 60 207;
    stroke-width: var(--menu--button__stroke-line);
}

.menu--button__opened .menu--button__line-2 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: var(--menu--button__stroke-line);
}

.menu--button__opened .menu--button__line-3 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: var(--menu--button__stroke-line);
}

.menu--button__opened .menu--button__line-4 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: var(--menu--button__stroke-line);
}
@media (min-width: 1024px) {
    .nav-bar-container button {
        display: none;
    }
}
